// Copyright © 2021 Move Closer

import { AsyncComponent } from 'vue'

import { HeroModuleAddonType } from './Hero.contracts'

/**
 * Registry that binds the `HeroModuleAddonType` with the applicable Vue form component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
export const heroModuleAddonTypeFormComponentRegistry: Record<HeroModuleAddonType, AsyncComponent> = {
  [HeroModuleAddonType.Heading]: () => import(
    /* webpackChunkName: "cms/HeadingAddonForm" */
    './addons/Heading/Heading.form.vue'
  ),
  [HeroModuleAddonType.Link]: () => import(
    /* webpackChunkName: "cms/LinkAddonForm" */
    './addons/Link/Link.form.vue'
  ),
  [HeroModuleAddonType.Text]: () => import(
    /* webpackChunkName: "cms/TextAddonForm" */
    './addons/Text/Text.form.vue'
  ),
  [HeroModuleAddonType.Search]: () => import(
    /* webpackChunkName: "cms/SearchAddonForm" */
    './addons/Search/Search.form.vue'
  )
}

/**
 * Registry that binds the `HeroModuleAddonType` with the applicable Vue UI component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const heroModuleAddonTypeUiComponentRegistry: Record<HeroModuleAddonType, AsyncComponent> = {
  [HeroModuleAddonType.Heading]: () => import(
    /* webpackChunkName: "frame" */
    './addons/Heading/Heading.ui.vue'
  ),
  [HeroModuleAddonType.Link]: () => import(
    /* webpackChunkName: "frame" */
    './addons/Link/Link.ui.vue'
  ),
  [HeroModuleAddonType.Text]: () => import(
    /* webpackChunkName: "frame" */
    './addons/Text/Text.ui.vue'
  ),
  [HeroModuleAddonType.Search]: () => import(
    /* webpackChunkName: "frame" */
    './addons/Search/Search.ui.vue'
  )
}
