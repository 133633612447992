// Copyright © 2022 Move Closer

import { HeroModuleAddon, HeroModuleAddonType } from '../../Hero.contracts'
import { HeadingFont } from '../../../../atoms/UiHeading/UiHeading.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
export interface HeadingAddon extends HeroModuleAddon<HeroModuleAddonType.Heading> {
  content: string

  variant?: HeadingVariant

  font?: HeadingFont
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 */
export enum HeadingVariant {
  Large = 'large',
  Normal = 'normal',
  XLarge = 'xlarge',
}
